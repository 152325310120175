<template>
  <div
    class="absolute bottom-5 top-5 left-5 z-30 bg-white shadow grid grid-cols-2 pr-4 pl-6 pt-5 grid-rows-layout w-module ml-5 rounded-xl"
    style="left: 68px"
  >
    <div
      class="text-darkblue col-start-1 col-end-2 text-xl font-bold whitespace-no-wrap font-SourceSansPro"
    >
      {{ $t('mailing.title') }}
    </div>
    <div class="flex justify-end col-start-2 items-center">
      <img
        v-if="notRole || role === 'CONTROLLER'"
        class="mr-5"
        alt=""
        src="../../assets/icons/history.svg"
        style="width: 18px; cursor: pointer"
        @click="$emit('show-history', { objects: 'mailings' })"
      />
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close')"
      />
    </div>
    <div
      class="col-start-1 col-end-3 pb-3 mt-6 border-b border-reallylightblue flex justify-between"
    >
      <skif-search
        v-model="filterString"
        class="w-full mr-4"
        :placeholder="$t('search')"
        @searching="searching"
      />
      <div v-if="checkedMailings.length">
        <el-tooltip
          :open-delay="700"
          effect="dark"
          :content="$t('delete')"
          placement="bottom"
          :offset="2"
          :visible-arrow="false"
        >
          <div
            class="focus_outline-none relative group"
            type="button"
            @click="deleteMailings"
          >
            <svg
              class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#91B4E7"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
              />
            </svg>
            <skif-badge list="list" :value="checkedMailings.length" />
          </div>
        </el-tooltip>
      </div>
      <skif-button
        variant="normal"
        style="height: 35px"
        v-if="checkedMailings.length"
        @click="cancel"
      >
        {{ $t('btn.cancel') }}
      </skif-button>
      <skif-button
        :disabled="disabledRole && role !== 'EDITOR'"
        v-else
        style="height: 35px"
        @click="$emit('create-mailing')"
      >
        {{ $t('add') }}
      </skif-button>
    </div>
    <div class="col-start-1 col-end-3 pr-2 overflow-y-auto">
      <table class="w-full table-fixed">
        <thead class="text-left">
          <tr>
            <th class="bg-white top-0 sticky z-10 w-10">
              <skif-checkbox
                class="p-2"
                @click.native="(event) => event.stopPropagation()"
                v-model="isAllChecked"
                :indeterminate="isIndeterminate"
                :disabled="!filterData.length"
              />
            </th>
            <th class="bg-white top-0 sticky w-40">
              <span
                class="text-annotationColor text-sm font-SourceSansPro leading-10 font-semibold"
              >
                {{ $t('mailing.name') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky truncate">
              <span
                class="text-annotationColor text-sm font-SourceSansPro leading-10 font-semibold"
              >
                {{ $t('mailing.date') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky w-30">
              <span
                class="text-annotationColor text-sm font-SourceSansPro leading-10 font-semibold"
              >
                {{ $t('mailing.isActive') }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody is="transition-group" move-class="transition duration-1000">
          <tr
            v-for="(mailing, index) in filterData"
            class="cursor-pointer hover_bg-hoverrow"
            @click="$emit('update-mailing', mailing.id)"
            :key="mailing.id"
          >
            <td style="width: 43px">
              <skif-checkbox
                class="p-2"
                v-model="checkedMailings"
                :label="mailing.id"
                :showLabel="false"
                @click.native="(event) => event.stopPropagation()"
              />
            </td>
            <td class="truncate pr-2">
              <el-tooltip
                :open-delay="700"
                effect="dark"
                :content="mailing.name"
                placement="bottom"
                :offset="0"
                :visible-arrow="false"
              >
                <span
                  class="font-bold leading-10 text-sm"
                  :class="
                    checkedMailings.includes(mailing.id)
                      ? 'text-headerText'
                      : 'text-darkblue'
                  "
                >
                  {{ mailing.name }}
                </span>
              </el-tooltip>
            </td>
            <td class="truncate pr-2">
              <span
                class="font-bold leading-10 text-sm"
                :class="
                  checkedMailings.includes(mailing.id)
                    ? 'text-headerText'
                    : 'text-darkblue'
                "
              >
                {{ mailing.last_run ? mailing.last_run.slice(5, -3) : '' }}
              </span>
            </td>
            <td>
              <skif-switch
                v-model="mailing.is_active"
                @click.native="(event) => event.stopPropagation()"
                @change="changeActivity(mailing.id, mailing.is_active)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  data() {
    return {
      filterString: '',
      checkedMailings: [],
    }
  },
  computed: {
    ...mapGetters('login', [
      'role',
      'changingCompany',
      'disabledRole',
      'notRole',
    ]),
    ...mapState('mailings', {
      mailings: (state) => state.mailings,
    }),
    isIndeterminate() {
      return (
        this.checkedMailings.length > 0 &&
        this.checkedMailings.length < this.filterData.length
      )
    },

    filterData() {
      return this.mailings.filter((mailing) =>
        JSON.stringify(mailing)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
      )
    },
    mailingsIds() {
      return this.mailings.map((m) => m.id)
    },
    isAllChecked: {
      get() {
        return (
          this.checkedMailings.length >= this.filterData.length &&
          this.filterData.length
        )
      },
      set(value) {
        this.checkedMailings = value ? this.mailingsIds : []
      },
    },
  },
  watch: {
    changingCompany() {
      this.filterString = ''
      this.cancel()
    },
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    changeActivity(id, isActive) {
      this.$store.dispatch('mailings/TOGGLE', {
        id: id,
        formData: { is_active: isActive },
      })
    },
    deleteMailings() {
      this.$store
        .dispatch('mailings/DELETE', this.checkedMailings)
        .then((_) => {
          this.cancel()
        })
    },
    cancel() {
      this.checkedMailings = []
    },
  },
}
</script>
